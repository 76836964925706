import React, { useEffect, useState } from "react";
import styles from "./BlogPost.module.css";
import HeroSection from "../../components/common/HeroSection/HeroSection";
import dropIco from "../../assets/icons/drop-down-ico.svg";
import NewsletterForm from "../../components/specific/NewsletterForm/NewsletterForm";
import BlogCard from "../../components/specific/BlogCard/BlogCard";
import Partners from "../../components/specific/Partners/Partners";
import PostComment from "../../components/specific/PostComment/PostComment";
import Spinner from "../../components/specific/Spinner/Spinner";
import { Link, useParams } from "react-router-dom";
import { formatDateGB } from "../../utils/helpers";
import { fetchBlogPost, fetchBlogPosts } from "../../api/apiClient";
import defaultImg from "../../assets/images/default.jpg";

const BlogPost = () => {
  let { slug } = useParams();

  const [blogs, setBlogs] = useState(null);
  const [blog, setBlog] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [blogsData, blogData] = await Promise.all([
          fetchBlogPosts(),
          fetchBlogPost(slug),
        ]);

        setBlogs(blogsData);
        setBlog(blogData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div>
      <HeroSection />
      <section className={styles.blog_section}>
        <div className="container">
          <div className={styles.blog_grid}>
            <div className={styles.left_post}>
              <div className={styles.post_banner}>
                <img
                  src={blog[0]?.featured_image_url || defaultImg}
                  alt={blog[0]?.title || ''}
                  className={styles.banner_img}
                />
              </div>
              <div className={styles.post_title}>
                <h1>{blog[0]?.title || ''}</h1>
                <p>{blog[0]?.authorId || ''} / {formatDateGB(blog[0]?.createdAt || '')}</p>
              </div>
              {blog && blog[0]?.blog_posts_contents.map((item, index) => (
              <p key={index} className={styles.post_description}>
                {item.content || ''}
              </p>
              ))}
              <div className={styles.impotent_line}>
                <p className={styles.post_description}>
                  Lorem ipsu -{" "}
                  <span>
                    Lorem ipsum dolor sit amTo be a top-tieLorem ipsum
                  </span>
                </p>
                <p className={styles.post_description}>
                  Lorem ipsu -{" "}
                  <span>
                    Lorem ipsum dolor sit amTo be a top-tieLorem ipsum
                  </span>
                </p>
              </div>
              <div className={styles.comment_form}>
                <h1>LEAVE A REPLY</h1>
                <p className={styles.post_description}>
                  Lorem ipsum dolor sit amTo be a top-tieLorem ipsum
                </p>
                <PostComment />
              </div>
            </div>
            <div className={styles.blog_grid_right}>
              <div className={styles.news_title}>
                <h1>Featured News</h1>
                <img
                  src={dropIco}
                  alt="Heaven Seven Hotel"
                  className={styles.drop_ico}
                />
              </div>
              {blogs && blogs.slice(0, 4).map((item, index) => (
              <div key={index} className={styles.news_card}>
                <div className={styles.news_card_banner}>
                  <img
                    src={item.featured_image_url}
                    alt={item.title || ""}
                    className={styles.blog_img}
                  />
                </div>

                <div className={styles.news_card_content}>
                  <Link to={`/blog-post/${item.slug}`}>
                  <h2>{item.title || ""}</h2>
                  </Link>
                  <div className={styles.news_card_post_detail}>
                    <p>{item.authorId}</p>
                    <p>{formatDateGB(item.createdAt)}</p>
                  </div>
                </div>
              </div>
              ))}
              <NewsletterForm />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.blog_cards_section}>
        <div className="container">
          <div className={styles.blog_cards}>
            <div className={styles.left_cards}>
              <div className={styles.medium_blogs_title}>
                <h1>Lorem ipsum dolor</h1>
                <span>View all</span>
              </div>
              <div className={styles.card_list}>
              {blogs && blogs.slice(0, 3).map((item, index) => (
                <BlogCard
                key={index}
                  cardImage={item.featured_image_url}
                  title={item.title || ""}
                  author={item.authorId}
                  date={formatDateGB(item.createdAt)}
                  desc={item.blog_posts_contents[0]?.content || ""}
                  link={item.slug}
                />
              ))}
              </div>
            </div>
            <div className={styles.right_cards}>
              <div className={styles.medium_blogs_title}>
                <h1>Lorem ipsum dolor</h1>
                <span>View all</span>
              </div>
              <div className="grid">
              {blogs && blogs.slice(0, 1).map((item, index) => (
                <BlogCard
                key={index}
                  cardImage={item.featured_image_url}
                  title={item.title || ""}
                  author={item.authorId}
                  date={formatDateGB(item.createdAt)}
                  desc={item.blog_posts_contents[0]?.content || ""}
                  link={item.slug}
                />
              ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Partners />
    </div>
  );
};

export default BlogPost;
