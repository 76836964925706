import React from "react";
import styles from "./OfferCard.module.css";
import defaultImg from "../../../assets/images/default.jpg";
import arrow from "../../../assets/icons/arrow-right.svg";
import { Link } from "react-router-dom";

const OfferCard = ({code, cardImage, title, desc, valid, hotel="Group", offer=0, property }) => {
  const OfferNav = hotel === "Group" ? `/offer/${code}` : `/offer/${hotel}/${code}`;
  return (
    <div className={styles.offer_card}>
      <div className={styles.offer_card_logo}>
        <img
          src={cardImage || defaultImg}
          alt="Haven Seven Hotels"
          className={styles.offer_card_image}
        />

        
      </div>
      <div className={styles.offer_card_body}>
      <p className={styles.offer_card_subtitle}>{property === "Group" ? 'Heaven Seven Hotel' : property}</p>
        <h4 className={styles.offer_card_title}>{title}</h4>

        <p className={styles.offer_card_description}>{desc}</p>

        <Link to={OfferNav} className={styles.offer_nav}>
          GET NOW <img
          src={arrow}
          alt="icon"
          className={styles.nav_icon}
        />
        </Link>

        <p className={styles.offer_card_valid}>Valid {valid}</p>
      </div>
    </div>
  );
};

export default OfferCard;
