import React, { useEffect, useState } from 'react';
import styles from './HeroSection.module.css';
import logo from "../../../assets/images/logo-ne.png";
import CheckRateCard from '../../specific/CheckRateCard/CheckRateCard';
import { fetchProperty, fetchPropertyImage } from '../../../api/apiClient';
import defaultImg from "../../../assets/images/default.jpg";
import Spinner from '../../specific/Spinner/Spinner';

const HeroSection = ({property="Group", page="Home"}) => {
  const [propertyImages, setPropertyImages] = useState(null);
  const [propertySingle, setProperty] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          imageData,
          propertyData
        ] = await Promise.all([
          fetchPropertyImage(`${property}`, "Slider", `${page}`, 10),
          fetchProperty(`${property}`),
        ]);


        setPropertyImages(imageData);
        setProperty(propertyData);
      } catch (err) {
        console.log(err);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [property, page]);

  if (loading) {
    return <div className={styles.loading}><Spinner loading={loading} /></div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  console.log('propertySingle', propertySingle);

  const backgroundStyle = {
    'backgroundImage': `url('${propertyImages[0]?.imgeUrl || defaultImg}')`,
    'backgroundSize': 'cover',
    'backgroundPosition': 'center center'
  }

  return (
    <div>
      <section className={styles.hero_section} style={backgroundStyle}>
        <div className={styles.logo}>
          <div>
            <img src={propertySingle?.logoUrl || logo} alt={propertySingle?.shortName} />
            <p>{property === "Group" ? 'Heaven Seven Hotel': propertySingle?.shortName}</p>
          </div>
        </div>
        <CheckRateCard />
      </section>
    </div>
  )
}

export default HeroSection;
