// constants.js

// API Endpoints
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PROPERTIES_ENDPOINT = `${API_BASE_URL}/properties_web`;
export const PROPERTY = `${API_BASE_URL}/property_web`;
export const PROPERTY_TEXT = `${API_BASE_URL}/property_text`;
export const PROPERTY_IMAGE = `${API_BASE_URL}/property_image_for_web`;
export const BANNER = `${API_BASE_URL}/web_banners`;
export const EXPERIENCES = `${API_BASE_URL}/experiences_for_web`;
export const OFFERS = `${API_BASE_URL}/offers_for_web`;
export const OFFER = `${API_BASE_URL}/offer_for_web`;
export const ROOMS = `${API_BASE_URL}/rooms_for_web`;
export const ROOM = `${API_BASE_URL}/room_single`;
export const RESTAURANTS = `${API_BASE_URL}/restaurant_for_web`;
export const RESTAURANT = `${API_BASE_URL}/restaurant_single`;
export const ROOM_TYPES = `${API_BASE_URL}/room_types_for_web`;
export const ROOM_TYPE = `${API_BASE_URL}/room_type_for_web`;
export const PROPERTY_SHORT = `${API_BASE_URL}/property_web_short`;
export const OFFER_SHORT = `${API_BASE_URL}/offer_types_short`;
export const BLOGS = `${API_BASE_URL}/blog_post_for_web`;

// Other constants
export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'es', 'fr', 'de'];
