import React, { useEffect, useState } from "react";
import styles from "./Blog.module.css";
import HeroSection from "../../components/common/HeroSection/HeroSection";
import dropIco from "../../assets/icons/drop-down-ico.svg";
import NewsletterForm from "../../components/specific/NewsletterForm/NewsletterForm";
import BlogCard from "../../components/specific/BlogCard/BlogCard";
import { fetchBlogPosts } from "../../api/apiClient";
import Spinner from "../../components/specific/Spinner/Spinner";
import defaultImg from "../../assets/images/default.jpg";
import { formatDateGB } from "../../utils/helpers";
import { Link } from "react-router-dom";

const Blog = () => {
  const [blogs, setBlogs] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [textData] = await Promise.all([fetchBlogPosts()]);

        setBlogs(textData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div>
      <HeroSection />
      <section className={styles.blog_section}>
        <div className="container">
          <div className={styles.blog_grid}>
            <div className={styles.blog_grid_left}>
              <div className={styles.blog_with_img}>
                <img
                  src={blogs[0]?.featured_image_url || defaultImg}
                  alt={blogs[0]?.title || ""}
                  className={styles.blog_img}
                />
                <div className={styles.blog_content}>
                <Link to={`/blog-post/${blogs[0]?.slug}`}>
                  <h1 className={styles.blog_title}>{blogs[0]?.title || ""}</h1>
                  </Link>
                  <p className={styles.blog_description}>
                    {blogs[0]?.blog_posts_contents[0]?.content || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.blog_grid_middle}>
              <div className={styles.blog_with_img}>
                <img
                  src={blogs[1]?.featured_image_url || defaultImg}
                  alt={blogs[1]?.title || ""}
                  className={styles.blog_img}
                />

                <div className={styles.blog_content}>
                <Link to={`/blog-post/${blogs[1]?.slug}`}>
                  <p className={styles.blog_description}>
                    {blogs[1]?.title || ""}
                  </p>
                  </Link>
                </div>
              </div>
              <div className={styles.blog_with_img}>
                <img
                  src={blogs[2]?.featured_image_url || defaultImg}
                  alt={blogs[2]?.title || ""}
                  className={styles.blog_img}
                />

                <div className={styles.blog_content}>
                <Link to={`/blog-post/${blogs[2]?.slug}`}>
                  <p className={styles.blog_description}>
                    {blogs[2]?.title || ""}
                  </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.blog_grid_right}>
              <div className={styles.news_title}>
                <h1>Featured News</h1>
                <img
                  src={dropIco}
                  alt="Heaven Seven Hotel"
                  className={styles.drop_ico}
                />
              </div>

              {blogs && blogs.slice(0, 4).map((item, index) => (
              <div key={index} className={styles.news_card}>
                <div className={styles.news_card_banner}>
                  <img
                    src={item.featured_image_url}
                    alt={item.title || ""}
                    className={styles.blog_img}
                  />
                </div>

                <div className={styles.news_card_content}>
                <Link to={`/blog-post/${item.slug}`}>
                  <h2>
                  {item.title || ""}
                  </h2>
                  </Link>
                  <div className={styles.news_card_post_detail}>
                    <p>{item.authorId}</p>
                    <p>{formatDateGB(item.createdAt)}</p>
                  </div>
                </div>
              </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <section className={styles.category_blog_section}>
        <div className="container">
          <dir className={styles.category_blogs}>
            <dir className={styles.category_blog}>
              <div className={styles.medium_blogs_title}>
                <h1>Lorem ipsum dolor</h1>
                <span>View all</span>
              </div>
              <dir className={styles.category_blog_logo}>
                <img
                  src={blogs[0]?.featured_image_url || defaultImg}
                  alt={blogs[0]?.title || ""}
                  className={styles.blog_img}
                />
              </dir>
              <dir className={styles.category_blog_content}>
              <Link to={`/blog-post/${blogs[0]?.slug}`}>
                <h1>{blogs[0]?.title || ""}</h1>
                </Link>
                <p>
                {blogs[0]?.blog_posts_contents[0]?.content || ""}
                </p>
                {blogs && blogs.slice(0, 2).map((item, index) => (
                <div key={index} className={styles.news_card}>
                  <div className={styles.news_card_banner}>
                    <img
                      src={item.featured_image_url}
                      alt={item.title || ""}
                      className={styles.blog_img}
                    />
                  </div>

                  <div className={styles.news_card_content}>
                  <Link to={`/blog-post/${item.slug}`}>
                    <h2>
                    {item.title || ""}
                    </h2>
                    </Link>
                    <div className={styles.news_card_post_detail}>
                      <p>{item.authorId}</p>
                      <p>{formatDateGB(item.createdAt)}</p>
                    </div>
                  </div>
                </div>
                ))}
              </dir>
            </dir>
            <dir className={styles.category_blog}>
              <div className={styles.medium_blogs_title}>
                <h1>Lorem ipsum dolor</h1>
                <span>View all</span>
              </div>
              <dir className={styles.category_blog_logo}>
                <img
                  src={blogs[1]?.featured_image_url || defaultImg}
                  alt={blogs[1]?.title || ""}
                  className={styles.blog_img}
                />
              </dir>
              <dir className={styles.category_blog_content}>
              <Link to={`/blog-post/${blogs[1]?.slug}`}>
                <h1>{blogs[1]?.title || ""}</h1>
                </Link>
                <p>
                {blogs[1]?.blog_posts_contents[0]?.content || ""}
                </p>
                {blogs && blogs.slice(0, 2).map((item, index) => (
                <div key={index} className={styles.news_card}>
                  <div className={styles.news_card_banner}>
                    <img
                      src={item.featured_image_url}
                      alt={item.title || ""}
                      className={styles.blog_img}
                    />
                  </div>

                  <div className={styles.news_card_content}>
                  <Link to={`/blog-post/${item.slug}`}>
                    <h2>
                    {item.title || ""}
                    </h2>
                    </Link>
                    <div className={styles.news_card_post_detail}>
                      <p>{item.authorId}</p>
                      <p>{formatDateGB(item.createdAt)}</p>
                    </div>
                  </div>
                </div>
                ))}
              </dir>
            </dir>
            <dir className={styles.stay_connected}>
              <div className={styles.medium_blogs_title}>
                <h1>Satay Conecteed</h1>
                <span>View all</span>
              </div>
              {blogs && blogs.slice(0, 1).map((item, index) => (
              <div key={index} className={styles.news_card}>
                <div className={styles.news_card_banner}>
                  <img
                    src={item.featured_image_url}
                    alt={item.title || ""}
                    className={styles.blog_img}
                  />
                </div>

                <div className={styles.news_card_content}>
                <Link to={`/blog-post/${item.slug}`}>
                  <h2>{item.title || ""}</h2>
                  </Link>
                  <div className={styles.news_card_post_detail}>
                    <p>{item.authorId}</p>
                    <p>{formatDateGB(item.createdAt)}</p>
                  </div>
                </div>
              </div>
              ))}
              <NewsletterForm />
            </dir>
          </dir>
        </div>
      </section>
      <section className={styles.blog_cards_section}>
        <div className="container">
          <div className={styles.blog_cards}>
            <div className={styles.left_cards}>
              <div className={styles.medium_blogs_title}>
                <h1>Lorem ipsum dolor</h1>
                <span>View all</span>
              </div>
              <div className={styles.card_list}>
              {blogs && blogs.slice(0, 3).map((item, index) => (
                <BlogCard
                key={index}
                  cardImage={item.featured_image_url}
                  title={item.title || ""}
                  author={item.authorId}
                  date={formatDateGB(item.createdAt)}
                  desc={item.blog_posts_contents[0]?.content || ""}
                  link={item.slug}
                />
              ))}
                
              </div>
            </div>
            <div className={styles.right_cards}>
              <div className={styles.medium_blogs_title}>
                <h1>Lorem ipsum dolor</h1>
                <span>View all</span>
              </div>
              {blogs && blogs.slice(0, 1).map((item, index) => (
                <BlogCard
                key={index}
                  cardImage={item.featured_image_url}
                  title={item.title || ""}
                  author={item.authorId}
                  date={formatDateGB(item.createdAt)}
                  desc={item.blog_posts_contents[0]?.content || ""}
                  link={item.slug}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
