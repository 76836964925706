import React, { useEffect, useState } from "react";
import styles from "./HotelExperiences.module.css";
import HeroSection from "../../../components/common/HeroSection/HeroSection";
import Card from "../../../components/specific/Card/Card";
import StayUs from "../../../components/common/StayUs/StayUs";
import logo from "../../../assets/images/logo-ne.png";
import OfferCard from "../../../components/specific/OfferCard/OfferCard";
import Partners from "../../../components/specific/Partners/Partners";
import Carousel from "../../../components/specific/Carousel/Carousel";
import { useParams } from "react-router-dom";
import {
  fetchExperiences,
  fetchOfferShort,
  fetchOffers,
  fetchPropertyShort,
  fetchPropertyText,
  fetchWebBanners,
} from "../../../api/apiClient";
import defaultImg from "../../../assets/images/default.jpg";
import FilterCard from "../../../components/specific/FilterCard/FilterCard";
import Spinner from "../../../components/specific/Spinner/Spinner";
import Banner from "../../../components/specific/Banner/Banner";

const HotelExperiences = () => {
  let { hotel } = useParams();

  const [propertyText, setPropertyText] = useState(null);
  const [banners, setBanners] = useState(null);
  const [experiences, setExperiences] = useState(null);
  const [offers, setOffers] = useState(null);

  const [hotels, setSelectedHotels] = useState(null);
  const [expTypes, setSelectedExpTypes] = useState(null);

  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedExpType, setSelectedExpType] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleFirstSelect = async (option) => {
    console.log("Selected Hotel:", option);
    try {
      setSelectedHotel(option.value);
      const expData = await fetchExperiences(option.value, selectedExpType);
      setExperiences(expData);
    } catch (err) {
      setError("Failed to fetch offers");
    }
  };

  const handleSecondSelect = async (option) => {
    console.log("Selected Exp Type:", option);
    try {
      setSelectedExpType(option.value);
      const expData = await fetchExperiences(selectedHotel, option.value);
      setExperiences(expData);
    } catch (err) {
      setError("Failed to fetch offers");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [textData, bannerData, experienceData, offerData, hotelsData, expTypeData] =
          await Promise.all([
            fetchPropertyText(hotel, "Experiences"),
            fetchWebBanners(hotel, "Experiences"),
            fetchExperiences(hotel, "Group"),
            fetchOffers(hotel, "Group"),
            fetchPropertyShort(),
            fetchOfferShort(),
          ]);

        setPropertyText(textData);
        setBanners(bannerData);
        setExperiences(experienceData);
        setOffers(offerData.slice(0, 3));
        const transformedHotelData = hotelsData.map((item) => ({
          value: item.shortName,
          label:
            item.shortName === "Group" ? "Heaven seven hotel" : item.shortName,
        }));

        const transformedExpTypeData = expTypeData.map((item) => ({
          value: item.type,
          label: item.type,
        }));

        setSelectedHotels(transformedHotelData);
        setSelectedExpTypes(transformedExpTypeData);
        setSelectedHotel(hotel);
        setSelectedExpType("Group");
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [hotel]);

  if (loading) {
    return <div className={styles.loading}><Spinner loading={loading} /></div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  // const visitBannerStyle = {
  //   backgroundImage: `url('${
  //     banners[0]?.banner_image_urls[0]?.imgeUrl || defaultImg
  //   }')`,
  //   backgroundSize: "cover",
  //   backgroundPosition: "center center",
  // };



  return (
    <div>
      <HeroSection property={hotel} page="Experiences" />

      <section className={styles.intro_ex_section}>
        <div className="container text-center">
          <h1 className="heading-secondary">
            {propertyText?.welcome_Header?.text || ""}
          </h1>
          <p className="description mb-sm">
            {propertyText?.welcome_paragraph_1?.text || ""}
          </p>
        </div>
      </section>

      <FilterCard 
        firstData={hotels}
        secondData={expTypes}
        onFirstSelect={handleFirstSelect}
        onSecondSelect={handleSecondSelect}
      />

      <section className={`text-center ${styles.tours_section}`}>
        <div className="container ">
          <div className={`grid col-3 ${styles.mobile_grid}`}>
            {experiences &&
              experiences.map((item, index) => (
                <Card
                  key={index}
                  logo={logo}
                  cardImage={
                    item.experiences_image_urls[0]?.imgeUrl || defaultImg
                  }
                  title={item.displayName}
                  subtitle={item.shortDescription}
                  desc={item.longDescription}
                  tag={true}
                  tagName={item.experiences_type.type}
                  button={true}
                  fixedText={true}
                />
              ))}
          </div>
        </div>
      </section>

      <section className={`text-center ${styles.offer_section}`}>
        <div className="container">
          <span className="subheading">Heaven Seven Hotel</span>
          <h1 className="heading-primary mb-lg">Special Offers</h1>

          <div className="desktop">
            <div className={`grid col-3 ${styles.mobile_grid}`}>
              {offers &&
                offers.map((item, index) => (
                  <OfferCard
                    key={index}
                    cardImage={item.offer_image_urls[0]?.imgeUrl}
                    title={item.displayName}
                    desc={item.longDescription}
                    valid={item.validityText}
                    code={item.code}
                    hotel={hotel}
                    offer={item.offerText}
                  />
                ))}
            </div>
          </div>
          <div className="mobile">
            <Carousel>
              {offers &&
                offers.map((item, index) => (
                  <OfferCard
                    key={index}
                    cardImage={item.offer_image_urls[0]?.imgeUrl}
                    title={item.displayName}
                    desc={item.longDescription}
                    valid={item.validityText}
                    code={item.code}
                    hotel={hotel}
                    offer={item.offerText}
                  />
                ))}
            </Carousel>
          </div>
        </div>
      </section>

      {/* <section className={styles.visit_section} style={visitBannerStyle}>
        <div className="container text-center">
          <div className={styles.visit_card}>
            <h3 className="heading-tertiary">{banners[0]?.displayName}</h3>
            <p className={styles.visit_card_description}>
              {banners[0]?.description}
            </p>
          </div>
        </div>
      </section> */}

      <Banner
        banner={banners[0]?.banner_image_urls[0]?.imgeUrl}
        title={banners[0]?.displayName || ""}
        description={banners[0]?.description || ""}
        isRight={true}
        bgColor="bg-secondary"
      ></Banner>

      <Partners />

      <StayUs />
    </div>
  );
};

export default HotelExperiences;
