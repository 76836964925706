import React, { useEffect, useState } from "react";
import styles from "./HotelDining.module.css";
import HeroSection from "../../../components/common/HeroSection/HeroSection";
import StayUs from "../../../components/common/StayUs/StayUs";
import DiningCard from "../../../components/specific/DiningCard/DiningCard";
import coffeeIco from "../../../assets/icons/coffee-ico.svg";
import Spinner from "../../../components/specific/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { fetchPropertyText, fetchRestaurants } from "../../../api/apiClient";
import defaultImg from "../../../assets/images/default.jpg";

const HotelDining = () => {
  let { hotel } = useParams();

  const [propertyText, setPropertyText] = useState(null);
  const [restaurants, setRestaurants] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [textData, restaurantData] = await Promise.all([
          fetchPropertyText(hotel, "Restaurant"),
          fetchRestaurants(hotel),
        ]);

        setPropertyText(textData);
        setRestaurants(restaurantData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [hotel]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }


  return (
    <div>
      <HeroSection property={hotel} page="Restaurant" />
      <section className={styles.welcome_section}>
        <div className="container text-center">
          <h1 className="heading-secondary">
            {propertyText?.welcome_Header?.text || ""}
          </h1>
          <p className="description mb-sm">
            {propertyText?.welcome_paragraph_1?.text || ""}
          </p>

          <div
            className={`grid col-3 ${styles.grid_card} ${styles.mobile_grid}`}
          >
            {restaurants &&
              restaurants.map((item, index) => (
                <DiningCard
                  key={index}
                  cardImage={
                    item.restaurant_image_urls[0]?.imgeUrl || defaultImg
                  }
                  title={item.displayName}
                  desc={item.shortDescription}
                />
              ))}
          </div>
        </div>
      </section>
      <section className={styles.dining_section}>
        <div className="container text-center">
          <h1 className="heading-secondary">
            {propertyText?.secondary_header?.text || ""}
          </h1>
          <p className="description mb-sm">
            {propertyText?.secondary_paragraph_1?.text || ""}
          </p>

          <div className={styles.restaurant_menu}>
            <div className={styles.menu_card}>
              <img src={coffeeIco} alt="menu ico" />
              <div className={styles.menu_card_content}>
                <h1>Breakfast Time</h1>
                <p>6 : 30 A.M - 10 : 00 A.M</p>
              </div>
            </div>

            <div className={styles.menu_card}>
              <img src={coffeeIco} alt="menu ico" />
              <div className={styles.menu_card_content}>
                <h1>Lunch Time</h1>
                <p>12 : 00 P.M - 2 : 00 P.M</p>
              </div>
            </div>

            <div className={styles.menu_card}>
              <img src={coffeeIco} alt="menu ico" />
              <div className={styles.menu_card_content}>
                <h1>Dinner Time</h1>
                <p>6 : 00 P.M - 9 : 00 P.M</p>
              </div>
            </div>

            <div className={styles.menu_card}>
              <img src={coffeeIco} alt="menu ico" />
              <div className={styles.menu_card_content}>
                <h1>Indoor Seating Capacity</h1>
                <p>180 Persons</p>
              </div>
            </div>

            <div className={styles.menu_card}>
              <img src={coffeeIco} alt="menu ico" />
              <div className={styles.menu_card_content}>
                <h1>Outdoor Seating Capacity</h1>
                <p>180 Persons</p>
              </div>
            </div>
          </div>
          <div className={styles.make_reservation}>
            <h1>MAKE YOUR ONLINE RESERVATION NOW</h1>
            <button className={styles.make_reservation_btn} type="button">
              RESERVE NOW
            </button>
          </div>
          <p className={styles.important_desc}>
            {propertyText?.tertiary_paragraph_1?.text || ""}
          </p>
        </div>
      </section>
      <StayUs />
    </div>
  );
};

export default HotelDining;
