import React from "react";
import styles from "./Banner.module.css";
import { Link } from "react-router-dom";
import arrow from "../../../assets/icons/arrow-right.svg";
import defaultImg from "../../../assets/images/default.jpg";
import { Parallax } from "react-parallax";

const Banner = ({ banner, title, description, isRight=true, bgColor="bg-primary",link=null, button=null }) => {

    // const bannerStyle = {
    //     backgroundImage: `url('${
    //       banner || defaultImg
    //     }')`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center center",
    //     backgroundAttachment: "scroll"
    //   };

    const bannerClass = `${styles.banner} ${isRight ? styles.colRight : styles.colLeft}`;
    


  return (
    <div className={bannerClass}>
    {/* <Parallax strength={300}>
        <Background className="custom-bg">
            <img src={banner || defaultImg} alt="fill murray" />
        </Background>
    </Parallax> */}
    <Parallax bgImage={banner || defaultImg} strength={300}>
    <div style={{ height: 400 }}>
      </div>
    </Parallax>
    {/* <div className={styles.banner_logo} style={bannerStyle}>
    </div> */}
      <div className={`${styles.bannerContent} ${styles[bgColor]}`}>
      <div>
        <h1>{title}</h1>
        <p>
          {description}
        </p>

        {link && (<Link to={`/${link}`} className={styles.banner_nav}>
            Explore More <img
          src={arrow}
          alt="icon"
          className={styles.nav_icon} />
        </Link>
        )}

        {button && (<Link to={`/${link}`} className={styles.banner_nav}>
            Book Now <img
          src={arrow}
          alt="icon"
          className={styles.nav_icon} />
        </Link>
        )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
