import React, { useEffect, useState } from "react";
import styles from "./OfferView.module.css";
import HeroSection from "../../../components/common/HeroSection/HeroSection";
import Button from "../../../components/common/Button/Button";
import OfferCard from "../../../components/specific/OfferCard/OfferCard";
import { fetchOffer, fetchOffers } from "../../../api/apiClient";
import Carousel from "../../../components/specific/Carousel/Carousel";
import Spinner from "../../../components/specific/Spinner/Spinner";
import { useParams } from "react-router-dom";
import defaultImg from "../../../assets/images/default.jpg";

const OfferView = () => {
  let { hotel } = useParams();
  let { offer } = useParams();

  if (!hotel) {
    hotel = 'Group';
  }

  const [offerSingle, setOffer] = useState(null);
  const [offers, setOffers] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [offerData, offersData] = await Promise.all([
          fetchOffer(offer),
          fetchOffers(hotel, "Group"),
        ]);
        setOffer(offerData);
        setOffers(offersData.slice(0, 3));
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [hotel, offer]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  const handleImageError = (event) => {
    event.target.src = defaultImg;
  };



  return (
    <div>
      <HeroSection property={hotel} page="Special Offer" />

      <section className={styles.welcome_section}>
        <div className="container">
          <h1 className="heading-secondary">Exclusive Offers</h1>
          <div className={styles.offer_grid}>
            <img
              src={offerSingle?.offer_image_urls[0]?.imgeUrl || defaultImg}
              alt={offerSingle?.offer_image_urls[0]?.imgAlt || ""}
              onError={handleImageError}
              className={styles.logo_section}
            />
            <div className={styles.offer_content}>
              <h1 className={styles.offer_title}>
                {offerSingle?.displayName || ""}
              </h1>
              <p className={styles.offer_description}>
                {offerSingle?.longDescription || ""}
              </p>

              <div className={styles.offer_card_grid}>
                <div className={styles.offer_card}>
                  <div>
                    <h1 className={styles.offer_card_title}>Book between</h1>
                    <p className={styles.offer_card_description}>
                      {offerSingle?.validityText || ""}
                    </p>
                  </div>
                </div>

                <div className={styles.offer_card}>
                  <h1 className={styles.offer_card_title}>
                    {offerSingle?.offerText || "0"}% OFF
                  </h1>
                </div>
              </div>

              <h2 className={styles.offer_condition}>Terms and Conditions</h2>
              {offerSingle?.offer_terms &&
                offerSingle?.offer_terms.map((item, index) => (
                  <li key={index} className={styles.offer_rate}>
                    {item.term}
                  </li>
                ))}
              <Button type="button" variant="primary">
                Book Now
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className={`text-center ${styles.offer_section}`}>
        <div className="container">
          <div className="desktop">
            <div className={`grid col-3 ${styles.mobile_grid}`}>
              {offers &&
                offers.map((item, index) => (
                  <OfferCard
                    key={index}
                    cardImage={item.offer_image_urls[0]?.imgeUrl}
                    title={item.displayName}
                    desc={item.longDescription}
                    valid={item.validityText}
                    code={item.code}
                    hotel={hotel}
                    offer={item.offerText}
                  />
                ))}
            </div>
          </div>
          <div className="mobile">
            <Carousel>
              {offers &&
                offers.map((item, index) => (
                  <OfferCard
                    key={index}
                    cardImage={item.offer_image_urls[0]?.imgeUrl}
                    title={item.displayName}
                    desc={item.longDescription}
                    valid={item.validityText}
                    code={item.code}
                    offer={item.offerText}
                  />
                ))}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OfferView;
