import React from "react";
import styles from "./AccommodationCard.module.css";
import dropIco from "../../../assets/icons/drop-down-ico.svg";
import { Link } from "react-router-dom";

const AccommodationCard = ({logo, title, subtitle, description, isLeft, features, hotel}) => {
  const contentClass = `${styles.card_content} ${isLeft ? styles.card_content_left : styles.card_content_right}`;
  const imageClass = `${styles.card_image} ${isLeft ? styles.card_image_right : styles.card_image_left}`;
  const featureClass = `${styles.card_feature} ${isLeft ? styles.card_feature_right : styles.card_feature_left}`;

  return (
    <div>
      <div className={styles.card}>
        <div className={imageClass}>
          <img src={logo} alt="welcome logo" />
        </div>
        <div className={contentClass}>
        <div className={styles.card_header}>
          <h1 className={styles.card_title}>{title}</h1>
          <p className={styles.card_subtitle}>{subtitle}</p>
          <p className={styles.card_description}>
            {description}
          </p>

        </div>
        <div className={featureClass}>
          <ul>
          
          {features &&
            features.map((item, index) => (
            <li key={index} >{item.description}</li>
          ))}
          </ul>

          <div className={styles.card_footer}>
            <Link>
            <img src={dropIco} alt="arrow" className={styles.right_icon} />
            Reserve this room
            </Link>
            <Link to={`/room/${encodeURIComponent(hotel)}/${encodeURIComponent(title)}`}>
            <img src={dropIco} alt="arrow" className={styles.right_icon} />
            View More
            </Link>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default AccommodationCard;
