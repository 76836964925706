import React from "react";
import styles from "./FilterCard.module.css";
import Button from "../../common/Button/Button";
import closeIco from "../../../assets/icons/close-ico.svg";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

const FilterCard = ({firstData, secondData, onFirstSelect, onSecondSelect}) => {



  const clearSelections = () => {
    onFirstSelect(null);
    onFirstSelect(null);
  };

  return (
    <div className={styles.filter_section}>
      <div className={styles.filter_group}>
        <label htmlFor="hotel">Hotel</label>
        <CustomDropdown options={firstData} placeholder="Select Hotel" onSelect={onFirstSelect}/>
      </div>
      <div className={styles.filter_group}>
        <label htmlFor="hotelType">Type</label>
        <CustomDropdown options={secondData} placeholder="Select Type" onSelect={onSecondSelect}/>
      </div>
      <div className={styles.filter_clear_button}>
        <Button type="button" variant="transparent" fullWidth={true}>
          <img src={closeIco} alt="close" className={styles.close_ico} onClick={clearSelections} />
          CLEAR ALL
        </Button>
      </div>
    </div>
  );
};

export default FilterCard;
