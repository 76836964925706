import {
  PROPERTIES_ENDPOINT,
  PROPERTY_TEXT,
  PROPERTY_IMAGE,
  BANNER,
  EXPERIENCES,
  PROPERTY,
  OFFERS,
  OFFER,
  ROOMS,
  ROOM,
  RESTAURANTS,
  RESTAURANT,
  ROOM_TYPES,
  ROOM_TYPE,
  PROPERTY_SHORT,
  OFFER_SHORT,
  BLOGS
} from "../utils/constants";

export const fetchProperties = async () => {
  const response = await fetch(`${PROPERTIES_ENDPOINT}`);
  const data = await response.json();
  return data;
};

export const fetchProperty = async (
  propertyName
) => {
  const response = await fetch(`${PROPERTY}/${propertyName}`);
  const data = await response.json();
  return data;
};

export const fetchPropertyText = async (property, page) => {
  const response = await fetch(`${PROPERTY_TEXT}/${property}/${page}`);
  const data = await response.json();
  return data;
};

export const fetchPropertyImage = async (
  propertyId,
  imgeType,
  pageName,
  imageCount
) => {
  const response = await fetch(`${PROPERTY_IMAGE}/${propertyId}/${imgeType}/${pageName}/${imageCount}`);
  const data = await response.json();
  return data;
};

export const fetchWebBanners = async (
  propertyName,
  pageName
) => {
  const response = await fetch(`${BANNER}/${propertyName}/${pageName}`);
  const data = await response.json();
  return data;
};

export const fetchExperiences = async (
  experiencesType,
  propertyName
) => {
  const response = await fetch(`${EXPERIENCES}/${experiencesType}/${propertyName}`);
  const data = await response.json();
  return data;
};

export const fetchOffers = async (
  propertyName,
  offerType
) => {
  const response = await fetch(`${OFFERS}/${propertyName}/${offerType}`);
  const data = await response.json();
  return data;
};

export const fetchOffer = async (
  offerCode
) => {
  const response = await fetch(`${OFFER}/${offerCode}`);
  const data = await response.json();
  return data;
};

export const fetchRooms = async (
  propertyName,
  roomTypeDisName
) => {
  const response = await fetch(`${ROOMS}/${propertyName}/${roomTypeDisName}`);
  const data = await response.json();
  return data;
};

export const fetchRoom = async (
  roomNo
) => {
  const response = await fetch(`${ROOM}/${roomNo}`);
  const data = await response.json();
  return data;
};

export const fetchRestaurants = async (
  propertyId
) => {
  const response = await fetch(`${RESTAURANTS}/${propertyId}`);
  const data = await response.json();
  return data;
};

export const fetchRestaurant = async (
  displayName
) => {
  const response = await fetch(`${RESTAURANT}/${displayName}`);
  const data = await response.json();
  return data;
};

export const fetchRoomTypes = async (
  propertyId
) => {
  const response = await fetch(`${ROOM_TYPES}/${propertyId}`);
  const data = await response.json();
  return data;
};

export const fetchRoomType = async (
  roomType
) => {
  const response = await fetch(`${ROOM_TYPE}/${roomType}`);
  const data = await response.json();
  return data;
};

export const fetchPropertyShort = async () => {
  const response = await fetch(`${PROPERTY_SHORT}`);
  const data = await response.json();
  return data;
};

export const fetchOfferShort = async () => {
  const response = await fetch(`${OFFER_SHORT}`);
  const data = await response.json();
  return data;
};

export const fetchBlogPosts = async () => {
  const response = await fetch(`${BLOGS}`);
  const data = await response.json();
  return data;
};

export const fetchBlogPost = async (slug) => {
  const response = await fetch(`${BLOGS}/${slug}`);
  const data = await response.json();
  return data;
};

