import React, { useState } from "react";
import styles from "./HotelCard.module.css";
import { Link } from "react-router-dom";
import arrow from '../../../assets/icons/arrow-right-ico.svg';
import defaultImg from '../../../assets/images/default.jpg';


const HotelCard = ({title, subtitle, desc, logo, gallery}) => {
  const [currentIndex, setCurrentSlide] = useState(0);
  const slides = gallery;

  const getVisibleImages = () => {
    const visibleImages = [];
    for (let i = 0; i < 3; i++) {
      visibleImages.push(slides[(currentIndex + i) % slides.length]);
    }
    return visibleImages;
  };

  const visibleImages = getVisibleImages();


  return (
    
    <div className={styles.hotel_card}>
      <div className={styles.hotel_card_image}>
      
        <img
          src={logo || defaultImg}
          alt="heaven Seven"
          className={styles.hotel_card_img}
        />
      </div>
      <div className={styles.hotel_card_content}>
        <Link to={`/hotel/${encodeURIComponent(title)}`} className={styles.hotel_card_title}>{title}</Link>
        <h5 className={styles.hotel_card_sub}>{subtitle}</h5>
        <p className={styles.hotel_card_description}>
          {desc}
        </p>

        <Link to={`/hotel/${encodeURIComponent(title)}`}  className={styles.hotel_card_explore} >
          <p>Explore More</p>
          <div className={styles.hotel_card_explore_link}>
            <img src={arrow} alt="arrow" />
          </div>
        </Link>
      </div>
      <div className={styles.hotel_card_gallery}>
        {visibleImages.map((gallery, index) => (
          <div key={index} className="slide">
            <img src={gallery.imgeUrl || defaultImg} alt={gallery.imgAlt} className={styles.hotel_card_gallery_img} />
          </div>
        ))}
        

        <div className={styles.dots}>
            {visibleImages.map((_, index) => (
              <div
                key={index}
                className={`${styles.dot} ${currentIndex === index ? styles.active : ''}`}
                onClick={() => setCurrentSlide(index)}
              ></div>
            ))}
          </div>
      </div>
    </div>
  );
};

export default HotelCard;
