import React, { useEffect, useState } from "react";
import styles from "./HotelRoomView.module.css";
import HeroSection from "../../../components/common/HeroSection/HeroSection";
import Button from "../../../components/common/Button/Button";
import RoomCard from "../../../components/specific/RoomCard/RoomCard";
import Spinner from "../../../components/specific/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { fetchRoomType, fetchRooms } from "../../../api/apiClient";
import defaultImg from "../../../assets/images/default.jpg";
import Carousel from "../../../components/specific/Carousel/Carousel";

const HotelRoomView = () => {
  let { hotel } = useParams();
  let { type } = useParams();

  const [roomTypes, setRoomType] = useState(null);
  const [rooms, setRooms] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [roomTypeData, roomData] = await Promise.all([
          fetchRoomType(type),
          fetchRooms(hotel, type),
        ]);

        setRoomType(roomTypeData);
        setRooms(roomData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [hotel, type]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  console.log("roomTypes", roomTypes);
  console.log("room", rooms);

  return (
    <div>
      <HeroSection property={hotel} page="Accommodation" />
      <section className={styles.room_grid_section}>
        <div className="container">
          <div className={styles.room_grid}>
            <img
              src={roomTypes?.room_type_image_urls[0]?.imgeUrl || defaultImg}
              alt={roomTypes?.room_type_image_urls[0]?.imgAlt || ""}
              className={styles.room_banner}
            />
            <div className={styles.room_content}>
              <h1 className={styles.room_title}>FEATURES</h1>
              <div className={styles.feature_group}>
                <ul className={styles.features}>
                  {roomTypes?.room_type_features &&
                    roomTypes?.room_type_features.map((item, index) => (
                      <li key={index}>{item.description}</li>
                    ))}
                </ul>
              </div>

              <Button type="button" variant="primary">
                Book Now
              </Button>
            </div>
          </div>
          <p className="description text-center">
            {roomTypes?.longDescription || ""}
          </p>
        </div>
      </section>
      <section className={styles.rooms_section}>
        <div className="container text-center">
          <h1 className="heading-secondary">Explore Our Rooms</h1>

          <div className="desktop">
            <div className={`grid col-3`}>
              {rooms &&
                rooms.map((item, index) => (
                  <RoomCard
                    key={index}
                    cardImage={item.room_image_urls[0]?.imgeUrl || defaultImg}
                    title={item.displayName}
                    room={item.roomNo}
                  />
                ))}
            </div>
          </div>
          <div className="mobile">
            <Carousel>
            {rooms &&
                rooms.map((item, index) => (
                  <RoomCard
                    key={index}
                    cardImage={item.room_image_urls[0]?.imgeUrl || defaultImg}
                    title={item.displayName}
                    room={item.roomNo}
                  />
                ))}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HotelRoomView;
