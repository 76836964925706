import React, { useEffect, useState } from "react";
import styles from "./HotelRestaurentView.module.css";
import HeroSection from "../../../components/common/HeroSection/HeroSection";
import timeIco from "../../../assets/icons/time-ico.svg";
import OfferCard from "../../../components/specific/OfferCard/OfferCard";
import { Link, useParams } from "react-router-dom";
import StayUs from "../../../components/common/StayUs/StayUs";
import { fetchOffers, fetchRestaurant } from "../../../api/apiClient";
import Carousel from "../../../components/specific/Carousel/Carousel";
import Spinner from "../../../components/specific/Spinner/Spinner";
import defaultImg from "../../../assets/images/default.jpg";

const HotelRestaurentView = () => {
  let { hotel } = useParams();
  let { restaurant } = useParams();

  const [restaurantSingle, setRestaurant] = useState(null);
  const [offers, setOffers] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [restaurantData, offerData] = await Promise.all([
          fetchRestaurant(restaurant),
          fetchOffers(hotel, "Group"),
        ]);
        setRestaurant(restaurantData);
        setOffers(offerData.slice(0, 3));
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [hotel,restaurant]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }


  return (
    <div>
      <HeroSection property={hotel} page="Restaurant" />

      <section className={styles.about_res_section}>
        <div className="container">
          <div className={styles.about_restaurant}>
            <img
              src={restaurantSingle?.restaurant_image_urls[0]?.imgeUrl || defaultImg}
              alt={restaurantSingle?.restaurant_image_urls[0]?.imgAlt || ''}
              className={styles.about_res_banner}
            />
            <div className={styles.about_restaurant_content}>
              <h1 className={styles.about_res_title}>
                {restaurantSingle.displayName || ''}
              </h1>
              <p className={styles.about_res_desc}>
                {restaurantSingle.longDescription || ''}
              </p>
              
              <div className={`grid col-2 ${styles.mobile_grid_col_2}`}>
                <div className={styles.menu_card}>
                  <img src={timeIco} alt="menu ico" />
                  <div className={styles.menu_card_content}>
                    <h1>DINNER</h1>
                    <p>6 : 00 P.M - 9 : 00 P.M</p>
                  </div>
                </div>
                <div className={styles.menu_card}>
                  <img src={timeIco} alt="menu ico" />
                  <div className={styles.menu_card_content}>
                    <h1>LUNCH</h1>
                    <p>12 : 00 P.M - 2 : 00 P.M</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.about_restaurant}>
            <img
              src={restaurantSingle?.restaurant_image_urls[1]?.imgeUrl || defaultImg}
              alt={restaurantSingle?.restaurant_image_urls[1]?.imgAlt || ''}
              className={styles.gallery_res}
            />
            <img
              src={restaurantSingle?.restaurant_image_urls[2]?.imgeUrl || defaultImg}
              alt={restaurantSingle?.restaurant_image_urls[2]?.imgAlt || ''}
              className={styles.gallery_res}
            />
          </div>
        </div>
      </section>

      <section className={`text-center ${styles.offer_section}`}>
        <div className="container">
          <span className="subheading">Heaven Seven Hotel</span>
          <h1 className="heading-primary mb-lg">Special Offers</h1>

          <div className="desktop">
            <div className={`grid col-3 ${styles.mobile_grid}`}>
              {offers &&
                offers.map((item, index) => (
                  <OfferCard
                    key={index}
                    cardImage={item.offer_image_urls[0]?.imgeUrl}
                    title={item.displayName}
                    desc={item.longDescription}
                    valid={item.validityText}
                    code={item.code}
                    hotel={hotel}
                    offer={item.offerText}
                  />
                ))}
            </div>
          </div>
          <div className="mobile">
            <Carousel>
              {offers &&
                offers.map((item, index) => (
                  <OfferCard
                    key={index}
                    cardImage={item.offer_image_urls[0]?.imgeUrl}
                    title={item.displayName}
                    desc={item.longDescription}
                    valid={item.validityText}
                    code={item.code}
                    hotel={hotel}
                    offer={item.offerText}
                  />
                ))}
            </Carousel>
          </div>

          <div className="read_more">
            <Link to={`/offers/${hotel}`}>View More</Link>
          </div>
        </div>
      </section>

      <StayUs />
    </div>
  );
};

export default HotelRestaurentView;
