import React from "react";
import styles from "./Card.module.css";
// import Button from "../../common/Button/Button";
import { Link } from "react-router-dom";
import arrow from "../../../assets/icons/down-arrow-head.svg";

const Card = ({
  cardImage,
  logo,
  title,
  subtitle,
  desc,
  button = false,
  tag = false,
  explore = false,
  fixedText = false,
  tagName = "TOUR",
  isFullWidth = false,
}) => {
  return (
    <div className={`${styles.card} ${isFullWidth ? styles.full_width : ""}`}>
      <div className={styles.card_logo}>
        <img
          src={cardImage}
          alt="Haven Seven Hotels"
          className={styles.card_image}
        />
        <img
          src={logo}
          alt="Haven Seven Hotels"
          className={styles.card_brand_logo}
        />
      </div>
      <div className={styles.card_body}>
      {explore && (
        <Link to={`/hotel/${encodeURIComponent(title)}`} className={styles.card_title}>{title}</Link>
      )}
      {button && (

        <h4 className={styles.card_title}>{title}</h4>
      )}
        <h4 className={styles.card_subtitle}>{subtitle}</h4>

        <p className={`${styles.card_description} ${fixedText ? styles.fixed_text : ''}`}>{desc}</p>
{/* <div className={styles.card_button}>
            <Button type="button" variant="primary" fullWidth={true}>
              EXPLORE
            </Button>
          </div> */}
        {button && (
          
          <div className={styles.explore_more}>
            <Link
              className={styles.explore_more_nav}
            >
              EXPLORE <img
          src={arrow}
          alt="icon"
          className={styles.nav_icon}
        />
            </Link>
          </div>
        )}

        {explore && (
          <div className={styles.explore_more}>
            <Link
              className={styles.explore_more_nav}
              to={`/hotel/${encodeURIComponent(title)}`}
            >
              Explore <img
          src={arrow}
          alt="icon"
          className={styles.nav_icon}
        />
            </Link>
          </div>
        )}
      </div>
      {tag && <div className={styles.card_tag}>{tagName}</div>}
    </div>
  );
};

export default Card;
