import React, { useEffect, useState } from "react";
import styles from "./Gallery.module.css";
import Partners from "../../components/specific/Partners/Partners";
import logo from "../../assets/images/logo-ne.png";

import playIco from "../../assets/icons/video-ico.svg";
import Card from "../../components/specific/Card/Card";
import HeroSection from "../../components/common/HeroSection/HeroSection";
import FilterCard from "../../components/specific/FilterCard/FilterCard";
import {
  fetchProperties,
  fetchPropertyImage,
  fetchPropertyText,
} from "../../api/apiClient";
import StayUs from "../../components/common/StayUs/StayUs";
import Spinner from "../../components/specific/Spinner/Spinner";

const Gallery = () => {
  const [propertyText, setPropertyText] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);
  const [propertyImages, setPropertyImages] = useState(null);
  const [properties, setProperties] = useState(null);
  const [visibleImages, setVisibleImages] = useState(3);
  const [visibleVideoImages, setVisibleVideoImages] = useState(3);

  

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleLoadMore = () => {
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 3); 
  };

  const handleVideoLoadMore = () => {
    setVisibleVideoImages((prevVisibleVideoImages) => prevVisibleVideoImages + 3);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [textData, imageData, galleryData, propertiesData] = await Promise.all([
          fetchPropertyText("Group", "Gallery"),
          fetchPropertyImage("Group", "Gallery", "Gallery", visibleVideoImages),
          fetchPropertyImage("Group", "Gallery", "Gallery", visibleImages),
          fetchProperties(),
        ]);

        setPropertyText(textData);
        setPropertyImages(imageData);
        setGalleryImages(galleryData);
        setProperties(propertiesData.filter((item) => item.id !== 14));
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [visibleImages, visibleVideoImages]);

  if (loading) {
    return <div className={styles.loading}><Spinner loading={loading} /></div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  const getItemClass = (index) => {
    switch (index) {
      case 0:
        return styles.gallery_grid_Item_1;
      case 1:
        return styles.gallery_grid_Item_2;
      case 2:
        return styles.gallery_grid_Item_3;
      default:
        return styles.gallery_grid_Item; 
    }
  };

  return (
    <div>
      <HeroSection property="Group" page="Gallery"/>

      <section className={styles.gallery_welcome_section}>
        <div className="container text-center">
          <h1 className="heading-secondary">
            {propertyText?.welcome_Header?.text || ""}
          </h1>
          <p className="description mb-sm">
            {propertyText?.welcome_paragraph_1?.text || ""}
          </p>
        </div>
      </section>

      <FilterCard />

      <section className={styles.gallery_grid_section}>
        <div className="container text-center">
          <div className={styles.gallery_grid}>
            <div className={styles.gallery_grid_Item_1}>
              <h3 className={styles.gallery_grid_title}>Images Gallery</h3>
              <div className={styles.gallery_grid_Item_img}>
                <img
                  src={propertyImages[0]?.imgeUrl}
                  alt={propertyImages[0]?.imgAlt}
                  className={styles.gallery_grid_img}
                />
              </div>
            </div>
            <div className={styles.gallery_grid_Item_2}>
              <img
                src={propertyImages[1]?.imgeUrl}
                alt={propertyImages[1]?.imgAlt}
                className={styles.gallery_grid_img}
              />
            </div>
            <div className={styles.gallery_grid_Item_3}>
              <img
                src={propertyImages[2]?.imgeUrl}
                alt={propertyImages[2]?.imgAlt}
                className={styles.gallery_grid_img}
              />
            </div>
            {galleryImages.slice(0, visibleImages).map((image, index) => (
            <div key={index} className={getItemClass(index)}>
              <div className={styles.gallery_grid_Item_img}>
                <img
                  src={image.imgeUrl}
                  alt={image.imgAlt}
                  className={styles.gallery_grid_img}
                />
              </div>
            </div>
          ))}
          </div>
          {visibleImages <= galleryImages.length && (
          <div className="read_more" onClick={handleLoadMore}>
            <p>Load More</p>
          </div>
        )}
        </div>
      </section>

      <section className={styles.gallery_grid_section}>
        <div className="container text-center">
          <div className={styles.gallery_grid}>
            <div className={styles.gallery_grid_Item_1}>
              <h3 className={styles.gallery_grid_title}>Video Gallery</h3>
              <div className={styles.gallery_grid_Item_img}>
                <img
                  src={propertyImages[4]?.imgeUrl}
                  alt={propertyImages[4]?.imgAlt}
                  className={styles.gallery_grid_img}
                />
                <img
                  src={playIco}
                  alt="play icon"
                  className={styles.gallery_grid_play}
                />
              </div>
            </div>
            <div className={styles.gallery_grid_Item_2}>
              <img
                src={propertyImages[5]?.imgeUrl}
                alt={propertyImages[5]?.imgAlt}
                className={styles.gallery_grid_img}
              />
              <img
                src={playIco}
                alt="play icon"
                className={styles.gallery_grid_play}
              />
            </div>
            <div className={styles.gallery_grid_Item_3}>
              <img
                src={propertyImages[6]?.imgeUrl}
                alt={propertyImages[6]?.imgAlt}
                className={styles.gallery_grid_img}
              />
              <img
                src={playIco}
                alt="play icon"
                className={styles.gallery_grid_play}
              />
            </div>
            {propertyImages.slice(0, visibleVideoImages).map((image, index) => (
            <div key={index} className={getItemClass(index)}>
              <img
                src={image.imgeUrl}
                  alt={image.imgAlt}
                  className={styles.gallery_grid_img}
              />
              <img
                src={playIco}
                alt="play icon"
                className={styles.gallery_grid_play}
              />
            </div>
          ))}
          </div>
          {visibleVideoImages <= propertyImages.length && (
            <div className="read_more" onClick={handleVideoLoadMore}>
              <p>Load More</p>
            </div>
          )}
        </div>
      </section>

      <section className={`text-center ${styles.hotels_section}`}>
        <div className={styles.hotel_list}>
          <div className="container ">
            <span className="subheading">Welcome To Haven Seven Hotels</span>
            <h1 className="heading-primary mb-lg">
              Life is short, travel often
            </h1>

            <div className={`d-flex ${styles.mobile_grid}`}>
              {properties &&
                properties.map((item) => (
                  <Card
                    key={item.id}
                    logo={logo}
                    cardImage={item.property_image_urls[0]?.imgeUrl}
                    title={item.shortName}
                    subtitle={item.longName}
                    desc={item.shortDescription}
                    explore={true}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>

      <Partners />

      <StayUs />
    </div>
  );
};

export default Gallery;
