import React, { useEffect, useState } from "react";
import styles from "./HotelAccommodation.module.css";
import HeroSection from "../../../components/common/HeroSection/HeroSection";
import AccommodationCard from "../../../components/specific/AccommodationCard/AccommodationCard";
import StayUs from "../../../components/common/StayUs/StayUs";
// import Button from "../../../components/common/Button/Button";
// import RoomCard from "../../../components/specific/RoomCard/RoomCard";
// import Carousel from "../../../components/specific/Carousel/Carousel";
import { fetchPropertyText, fetchRoomTypes } from "../../../api/apiClient";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/specific/Spinner/Spinner";
import defaultImg from "../../../assets/images/default.jpg";

const HotelAccommodation = () => {
  let { hotel } = useParams();

  const [propertyText, setPropertyText] = useState(null);
  const [roomTypes, setRoomType] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [textData, roomTypeData] = await Promise.all([
          fetchPropertyText(hotel, "Accommodation"),
          fetchRoomTypes(hotel),
        ]);

        setPropertyText(textData);
        setRoomType(roomTypeData);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [hotel]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  console.log("roomTypes", roomTypes);

  return (
    <div>
      <HeroSection property={hotel} page="Accommodation" />

      <section className={styles.welcome_section}>
        <div className="container text-center">
          <h1 className="heading-secondary">
            {propertyText?.welcome_Header?.text || ""}
          </h1>
          <p className="description mb-sm">
            {propertyText?.welcome_paragraph_1?.text || ""}
          </p>
          {roomTypes &&
            roomTypes.map((item, index) => (
              <AccommodationCard
                key={index}
                logo={item.room_type_image_urls[0]?.imgeUrl || defaultImg}
                title={item.displayName}
                subtitle={item.subTitle}
                description={item.shortDescription}
                features={item.room_type_features}
                isLeft={index % 2 !== 0 ? true : false}
                hotel={hotel}
              />
            ))}
        </div>
      </section>
      {/* <section className={styles.rooms_section}>
        <div className="container text-center">
          <div className={styles.delux_rooms}>
            <h1 className={styles.room_title}>Delux Rooms</h1>
            <div className="desktop">
              <div className={`grid col-3 ${styles.mobile_grid}`}>
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
              </div>
            </div>
            <div className="mobile">
              <Carousel>
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
              </Carousel>
            </div>
          </div>
          <div className={styles.suit_rooms}>
            <h1 className={styles.room_title}>Suit Rooms</h1>
            <div className="desktop">
              <div className={`grid col-3 ${styles.mobile_grid}`}>
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
              </div>
            </div>
            <div className="mobile">
              <Carousel>
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
                <RoomCard
                  cardImage="http://mdev.miracleclouds.com/heaven-seven/web/images/heaven-seven.jpg"
                  title="Double Dulx Room 01"
                  room={104}
                />
              </Carousel>
            </div>
          </div>

          <p className="description mb-sm">
            Escape to luxury in our Grand Rooms, where comfort meets elegance.
            Unwind amidst stunning views and modern amenities for a truly
            unforgettable stay at Heaven Seven.
          </p>

          <Button type="button" variant="primary">
            Book Now
          </Button>
        </div>
      </section> */}
      <StayUs />
    </div>
  );
};

export default HotelAccommodation;
