import React from "react";
import { PuffLoader } from "react-spinners";

const Spinner = ({ loading }) => {
  const spinnerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };
  return (
    <div style={spinnerStyle}>
      <PuffLoader loading={loading} size={40} />
    </div>
  );
};

export default Spinner;
